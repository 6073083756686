export default class AppSettings {
    public static clientId: string = "1dcc9f37-8897-4586-93d5-d07dccf15a8a";
  public static tenant: string = "common";
  // this is WebAPI URL for local development
  //  public static apiRoot: string = "https://localhost:44302/api/";
  // WebAPI URL for published app
  public static apiRoot: string =  "https://ncreaswebapi.azurewebsites.net/api/";
  public static apiScopes: string[] = [
    "api://" + AppSettings.clientId + "/Reports.Embed"
  ];
}